// Unauthorized.js
import React, { useState, useEffect } from "react";
import { Box, Grid, Card } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

function Unauthorized() {
  return (
    <DashboardLayout>
          <DashboardNavbar />
          <MDBox pt={6} pb={3}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography
                      variant="h6"
                      color="white"
                      display="flex"
                      alignItems="bottom"
                      gap="5px"
                    >
                      unauthorized
                    </MDTypography>
                  </MDBox>
                  <MDBox pt={3} display= 'flex' alignItems= "center" justifyContent = "center">
                  <MDTypography
                      variant="h6"
                      color="white"
                      display="flex"
                      alignItems="bottom"
                      gap="5px"
                    >
                      401 Unauthorized
                    </MDTypography>
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
          <Footer />
        </DashboardLayout>
  )
}

export default Unauthorized;
