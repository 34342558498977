import { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

const FilePreview = ({ file, onRemove }) => {
  const [fileURL, setFileURL] = useState(null);
  const fileLinkReplacer = (link) => {
    try {
      // Replace backslashes with forward slashes and get the filename from the path
      const updatedLoc = link.replaceAll("\\", "/").split("/").pop();
      console.log("File name:", updatedLoc);
      // return `http://localhost:8080/uploads/${updatedLoc}`;
      // Return the file URL accessible via the backend server
      return `https://server.belestech.com/uploads/${updatedLoc}`;
    } catch (error) {
      console.error("Error generating file URL:", error);
      return "";
    }
  };

  // Create a URL for the file when the component mounts
  useEffect(() => {
    if (file instanceof File) {
      setFileURL(URL.createObjectURL(file));
    } else {
      // Assuming `file` is an existing file object with a URL or path for update mode
      setFileURL(fileLinkReplacer( file)); 
    }
    return () => {
      if (fileURL) URL.revokeObjectURL(fileURL);
    };
  }, [file]);

  const handleFileOpen = () => {
    if (fileURL) window.open(fileURL, "_blank");
  };

  return (
    <MDBox display="flex" alignItems="center" mb={1} mr={2}>
      <MDTypography
        variant="body2"
        style={{ cursor: "pointer", textDecoration: "underline", marginRight: "8px" }}
        onClick={handleFileOpen}
      >
        {file.name || "File"}
      </MDTypography>
      <MDButton variant="outlined" color="error" size="small" onClick={onRemove}>
        Remove
      </MDButton>
    </MDBox>
  );
};

export default FilePreview;
