import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "context"; // Adjust the path as neede
const ProtectedRoute = ({ allowedRoles , children }) => {

  const { isAuthenticated, userRole } = useContext(AuthContext);

  if (!isAuthenticated) {
    return <Navigate to="/auth/login" replace />;
  }

  if (!allowedRoles.includes(userRole)) {
    return <Navigate to="/unauthorized" />; // Redirect or show a message if unauthorized
  }
  
  return children;
};

export default ProtectedRoute;
