import React, { useState, useEffect } from "react";
import { Box, Grid, Card } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import UserProfile from "./form";
import authorsTableData from "layouts/user-management/data";

const UserManagement = () => {
  const { columns, rows, update } = authorsTableData();
  const [view, setView] = useState("view");
  const [updatingItem, setUpdatingItem] = useState({});

  // Add a check to ensure setView isn't called unnecessarily.
  useEffect(() => {
    // Ensure we only update the view if it's currently not in the update state
    if (update.isCurrentStateUpdate && view !== "update") {
      setView("update");
      setUpdatingItem(update.selectedUserForUpdate);
    }
  }, [update.isCurrentStateUpdate, update.selectedUserForUpdate, view]);
  // Handle create button clicked
  const createButtonClicked = () => {
    if (view !== "create") {
      setView("create");
    }
  };
  const closeUserProfile = () => {
    update.closeTheUpdateStateForm()
    setView('view'); // Close the form
  };
  return (
    <Box>
      {view === "view" && (
        <DashboardLayout>
          <DashboardNavbar />
          <MDBox pt={6} pb={3}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography
                      variant="h6"
                      color="white"
                      display="flex"
                      alignItems="bottom"
                      gap="5px"
                    >
                      Users Table{" "}
                      <MDBox onClick={createButtonClicked}>
                        <img
                          src="/icons8-edit-100.png"
                          width={20}
                          alt="create"
                        />
                      </MDBox>
                    </MDTypography>
                  </MDBox>
                  <MDBox pt={3}>
                    <DataTable
                      table={{ columns, rows }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
          <Footer />
        </DashboardLayout>
      )}
      {view === "create" && <UserProfile isUpdateMode = {update.isCurrentStateUpdate} onClose={closeUserProfile}/>}
      {view === "update" && <UserProfile user={updatingItem} isUpdateMode = {update.isCurrentStateUpdate} onClose={closeUserProfile}/>}
    </Box>
  );
};

export default UserManagement;

