// Imports for React hooks
import { useEffect, useState } from "react";

// Other necessary imports
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import AuthService from "../../../services/auth-service";
import defaultAvatar from "assets/images/team-2.jpg"; // Default image if no user image is available

export default function Data() {
  const [testimonialsData, setTestimonialsData] = useState([]);
  const [selectedTestimonialForUpdate, setSelectedTestimonialForUpdate] = useState({});
  const [isCurrentStateUpdate, setIsCurrentStateUpdate] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await AuthService.getTestimonials();
        if (data.error) {
          return console.error(data.error); // Log error if present
        }

        const allTestimonialsData = data.data;

        // Fetch author/user names for each testimonial in parallel
        const updatedData = await Promise.all(
          allTestimonialsData.map(async (testimonial) => {
            const userData = await AuthService.getUser(testimonial.userId);
            return { ...testimonial, userName: userData.name };
          })
        );

        setTestimonialsData(updatedData);
      } catch (error) {
        console.error("Error fetching testimonial data:", error);
      }
    }

    fetchData();
  }, []);

  const updateTestimonialClicked = (testimonial) => {
    setSelectedTestimonialForUpdate(testimonial);
    setIsCurrentStateUpdate(true);
  };

  const closeTheUpdateStateForm = () => {
    setIsCurrentStateUpdate(false);
  };

  const deleteTestimonialClicked = async (testimonialId) => {
    const isConfirmed = window.confirm(`Are you sure you want to delete testimonial ${testimonialId}?`);
    if (!isConfirmed) return;

    const response = await AuthService.deleteTestimonials(testimonialId);
    if (response.error) {
      return console.error(response.error);
    }

    // Remove the deleted testimonial from the state
    setTestimonialsData(testimonialsData.filter((testimonial) => testimonial.testimonialsId !== testimonialId));
  };

  // Define Author and Quote components for testimonials
  const Author = ({ image, name }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image || defaultAvatar} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  const Quote = ({ quote }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography variant="caption" color="textSecondary">
        {quote.length > 80 ? `${quote.substring(0, 80)}...` : quote}
      </MDTypography>
    </MDBox>
  );

  // Map fetched data to rows
  const rows = testimonialsData.map((testimonial) => ({
    quote: (
      <Quote quote={testimonial.quote} />
    ),
    author: (
      <Author image={testimonial.userImage} name={testimonial.userName} />
    ),
    date: (
      <MDTypography component="span" variant="caption" color="text" fontWeight="medium">
        {new Date(testimonial.date).toLocaleDateString()}
      </MDTypography>
    ),
    action: (
      <MDBox display="flex" gap="8px">
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium" onClick={() => updateTestimonialClicked(testimonial)}>
          Edit
        </MDTypography>
        <MDTypography component="a" href="#" variant="caption" color="text" onClick={() => deleteTestimonialClicked(testimonial.testimonialsId)} fontWeight="medium">
          Delete
        </MDTypography>
      </MDBox>
    ),
  }));

  // Return columns and rows for table
  return {
    columns: [
      { Header: "Quote", accessor: "quote", width: "45%", align: "left" },
      { Header: "Author", accessor: "author", align: "left" },
      { Header: "Date", accessor: "date", align: "center" },
      { Header: "Action", accessor: "action", align: "center" },
    ],
    rows,
    tUpdate: {
      isCurrentStateUpdate,
      selectedTestimonialForUpdate,
      closeTheUpdateStateForm,
    },
  };
}



