import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import carreropportunityData from "layouts/testimonialsAndCareerOpportunites/data/carreropportunityData.js";
import testimonialsData from "layouts/testimonialsAndCareerOpportunites/data/testimonialsData.js";

import Form from './form.js';
import { useEffect, useState } from "react";

function Tables() {
  
  const { columns, rows, oUpdate } = carreropportunityData();
  const { columns: pColumns, rows: pRows, tUpdate } = testimonialsData();
  
  const [view, setView] = useState('view');
  const [entityType, setEntityType] = useState('');
  const [selected, setSelected] = useState({});
  
  const opportunityCreateButtonClicked = () => {
    setView('create');
    setEntityType('opportunity');
  };

  const testimonialCreateButtonClicked = () => {
    setView('create');
    setEntityType('testimonial');
  };

  useEffect(() => {
    if (oUpdate?.isCurrentStateUpdate || tUpdate?.isCurrentStateUpdate) {
      setView('update');
      if (oUpdate?.isCurrentStateUpdate) {
        setSelected(oUpdate.selectedOpportunityForUpdate);
        setEntityType('opportunity');
      } else if (tUpdate?.isCurrentStateUpdate) {
        setSelected(tUpdate.selectedTestimonialForUpdate);
        setEntityType('testimonial');
      }
    }
  }, [oUpdate, tUpdate]);

  const closeUserProfile = () => {
    oUpdate?.closeTheUpdateStateForm();
    tUpdate?.closeTheUpdateStateForm();
    setView('view');
  };
  
  if (view === 'view') {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox pt={6} pb={3}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white" display="flex" alignItems="center" gap="5px">
                    Career Opportunity
                    <MDBox onClick={opportunityCreateButtonClicked} style={{ cursor: "pointer" }}>
                      <img
                        src="/icons8-edit-100.png"
                        width={20}
                        alt="create"
                      />
                    </MDBox>
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  <DataTable
                    table={{ columns, rows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                </MDBox>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <MDBox
                  mx={2}
                  mt={-3}
                  py={3}
                  px={2}
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="info"
                >
                  <MDTypography variant="h6" color="white" display="flex" alignItems="center" gap="5px">
                    Testimonials
                    <MDBox onClick={testimonialCreateButtonClicked} style={{ cursor: "pointer" }}>
                      <img
                        src="/icons8-edit-100.png"
                        width={20}
                        alt="create"
                      />
                    </MDBox>
                  </MDTypography>
                </MDBox>
                <MDBox pt={3}>
                  <DataTable
                    table={{ columns: pColumns, rows: pRows }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                </MDBox>
              </Card>
            </Grid>
          </Grid>
        </MDBox>
        <Footer />
      </DashboardLayout>
    );
  }

  return (
    <Form
      entity={view === 'create' ? {} : selected}
      isUpdateMode={view === 'update'}
      entityType={entityType}
      onClose={closeUserProfile}
    />
  );
}

export default Tables;

