import React, { useState, useEffect } from "react";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDProgress from "components/MDProgress";
import AuthService from "../../../services/auth-service";
import team2 from "assets/images/team-2.jpg";

export default function PublicationsTable() {
  const [publications, setPublications] = useState([]);
  const [selectedPublicationForUpdate, setSelectedPublicationForUpdate] = useState({});
  const [isCurrentStateUpdate, setIsCurrentStateUpdate] = useState(false);

  useEffect(() => {
    const fetchPublications = async () => {
      try {
        const response = await AuthService.getPublications();
        if (response.error) {
          console.error(response.error);
          return;
        }
        const allPublicationData = response.data;

        const updatedData = await Promise.all(
          allPublicationData.map(async (publication) => {
            try {
              const author = await AuthService.getUser(publication.author);
              return { ...publication, authorName: author.name };
            } catch (err) {
              console.error("Error fetching author data:", err);
              return { ...publication, authorName: "Unknown Author" };
            }
          })
        );

        setPublications(updatedData);
      } catch (error) {
        console.error("Error fetching publication data:", error);
      }
    };

    fetchPublications();
  }, []);

  const updatePublicationClicked = (publication) => {
    setSelectedPublicationForUpdate(publication);
    setIsCurrentStateUpdate(true);
  };

  const closeTheUpdateStateForm = () => {
    setIsCurrentStateUpdate(false);
  };

  const deletePublicationClicked = async (publicationId) => {
    const isConfirmed = window.confirm(`Are you sure you want to delete publication ${publicationId}?`);
    if (!isConfirmed) return;

    try {
      const response = await AuthService.deletePublications(publicationId);
      if (response.error) {
        console.error(response.error);
        return;
      }
      setPublications(publications.filter((pub) => pub.publicationId !== publicationId));
    } catch (error) {
      console.error("Error deleting publication:", error);
    }
  };

  const Publication = ({ file, title, author, description }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={file || team2} name={author} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {title}
        </MDTypography>
        <MDTypography variant="caption" color="textSecondary">
          {description.length > 50 ? `${description.substring(0, 50)}...` : description}
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  const rows = publications.map((publication) => ({
    publication: (
      <Publication
        file={publication.file}
        title={publication.title}
        author={publication.authorName}
        description={publication.description}
      />
    ),
    author: (
      <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
        {publication.authorName}
      </MDTypography>
    ),
    date: (
      <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
        {new Date(publication.date).toLocaleDateString()}
      </MDTypography>
    ),
    status: (
      <MDBox ml={-1}>
        <MDBadge
          badgeContent={publication.status}
          color={publication.status === "published" ? "success" : "dark"}
          variant="gradient"
          size="sm"
        />
      </MDBox>
    ),
    action: (
      <MDBox display="flex" gap="8px">
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium" onClick={() => updatePublicationClicked(publication)}>
          Edit
        </MDTypography>
        <MDTypography component="a" href="#" variant="caption" color="text" onClick={() => deletePublicationClicked(publication.publicationId)} fontWeight="medium">
          Delete
        </MDTypography>
      </MDBox>
    ),
  }));

  return {
    columns: [
      { Header: "publication", accessor: "publication", width: "30%", align: "left" },
      { Header: "author", accessor: "author", align: "left" },
      { Header: "date", accessor: "date", align: "center" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],
    rows,
    pubUpdate: {
      isCurrentStateUpdate,
      selectedPublicationForUpdate,
      closeTheUpdateStateForm,
    },
  };
}

