import React, { useState, useEffect } from "react";
import axios from "axios";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDProgress from "components/MDProgress";
import AuthService from "../../../services/auth-service";
import team2 from "assets/images/team-2.jpg";

export default function ProjectsTable() {
  const [projects, setProjects] = useState([]);
  const [selectedProjectForUpdate, setSelectedResearchForUpdate] = useState({})
  const [isCurrentStateUpdate, setIsCurrentStateUpdate] = useState(false)
  const [referesh, setReferesh] = useState(true)
  
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await AuthService.getProjects();
        if (response.error) {
          return console.error(data.error); // Log the error if the response contains an error
        }
        const allProjectData = response.data;
  
        // Fetch lead names for each research in parallel using Promise.all
        const updatedData = await Promise.all(
          allProjectData.map(async (project) => {
            // Fetch lead researcher's data based on research.leadResearch
            const research = await AuthService.getResearch(project.researchId);
            return { ...project, researchName: research.title };
          })
        );
        setProjects(updatedData);
      } catch (error) {
        console.error("Error fetching project data:", error);
      }
    };

    fetchProjects();
  }, [referesh]);

  const updateProjectClicked = (research) => {
    setSelectedResearchForUpdate(research)
    setIsCurrentStateUpdate(true)
  }
  const pRefereshPage = ()=> {
    setReferesh(prev => prev ? false : true)
  }
  const closeTheUpdateStateForm = ()=>{
    setIsCurrentStateUpdate(false)
    pRefereshPage()
  }

  const deleteProjectClicked = async (projectId) => {
    const isConfirmed = window.confirm(`Are you sure you want to delete user ${projectId}?`);
    // If user cancels, do nothing
    if (!isConfirmed) return;

    const response = await AuthService.deleteProjects(projectId);
    if(response.error){
      return console.error(response.error)
    }
    PrefereshPage()
  }

  const Project = ({ image, name, title, description }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      {/* <MDAvatar src={image || team2} name={name} size="sm" /> */}
      <MDBox ml={2} lineHeight={1}  display="flex" flexDirection="column" gap="4px">
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        {/* Display the title and part of the description */}
        {/* <MDBox display= 'flex' flex-direction = 'column' gap = '4px'> */}
          <MDTypography variant="caption" fontWeight="medium" color="textSecondary">
            {title}
          </MDTypography>
          <MDTypography variant="caption" color="textSecondary">
            {description.length > 50 ? `${description.substring(0, 30)}...` : description}
          </MDTypography>
        {/* </MDBox> */}
      </MDBox>
    </MDBox>
  );

  const Progress = ({ color, value }) => (
    <MDBox display="flex" alignItems="center">
      <MDTypography variant="caption" color="text" fontWeight="medium">
        {value}%
      </MDTypography>
      <MDBox ml={0.5} width="9rem">
        <MDProgress variant="gradient" color={color} value={value} />
      </MDBox>
    </MDBox>
  );

  // Helper function to get progress value based on status
  const getProgressValue = (status) => {
    switch (status) {
      case "completed":
        return 100;
      case "starting":
        return 0;
      case "ongoing":
        return 50;
      default:
        return 0;
    }
  };

  // Helper function to calculate remaining days
  const calculateRemainingDays = (startDate, endDate) => {
    const currentDate = new Date();
    const start = new Date(startDate);
    const end = new Date(endDate);

    if (currentDate < start) {
      return "Not started";
    } else if (currentDate > end) {
      return "Closed";
    } else {
      const diffTime = Math.ceil((end - currentDate) / (1000 * 60 * 60 * 24)); // Convert milliseconds to days
      return `${diffTime} days`;
    }
  };

  // Dynamically generate rows
  const rows = projects.map((project) => ({
    project: (
      <Project
        image={project.image}
        title={project.title}
        description={project.description}
      />
    ),
    research: (
      <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
        {project.researchName.length > 20 ? `${project.researchName.substring(0, 20)}...` : project.researchName}
      </MDTypography>
    ),
    status: (
      <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
        {project.status}
      </MDTypography>
    ),
    completion: <Progress color={getProgressValue(project.status) === 100 ? "success" : "info"} value={getProgressValue(project.status)} />,
    remainingDays: (
      <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
        {calculateRemainingDays(project.startDate, project.endDate)}
      </MDTypography>
    ),
    action: (
      <MDBox display = 'flex' gap = '8px'>
        <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium" onClick = {() => updateProjectClicked(project)}>
          Edit
        </MDTypography>
        <MDTypography component="a" href="#" variant="caption" color="text" onClick = {() => deleteProjectClicked(project.projectId)} fontWeight="medium">
          Delete
        </MDTypography>
      </MDBox>
    ),
  }));

  return {
    columns: [
      { Header: "project", accessor: "project", width: "20%", align: "left" },
      { Header: "research", accessor: "research", align: "left" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "completion", accessor: "completion", align: "center" },
      { Header: "remaining days", accessor: "remainingDays", align: "center" }, // New column added
      { Header: "action", accessor: "action", align: "center" },
    ],
    rows,
    pUpdate: {
      isCurrentStateUpdate,
      selectedProjectForUpdate,
      closeTheUpdateStateForm, 
      pRefereshPage
    }
  };
}

