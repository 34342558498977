
import axios from "axios";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDProgress from "components/MDProgress";
import AuthService from "../../../services/auth-service";
import team2 from "assets/images/team-2.jpg";
import { useEffect, useState, useContext } from "react";
import { AuthContext } from "context"; // Adjust the path as neede

export default function EventsTable() {
  const { isAuthenticated, userRole } = useContext(AuthContext);
  const [events, setEvents] = useState([]);
  const [selectedEventForUpdate, setSelectedEventForUpdate] = useState({});
  const [isCurrentStateUpdate, setIsCurrentStateUpdate] = useState(false);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await AuthService.getEvents();
        if (response.error) {
          return console.error(response.error); // Log the error if the response contains an error
        }
        const allEventData = response.data;
  
        // Fetch associated research title for each event in parallel using Promise.all
        const updatedData = await Promise.all(
          allEventData.map(async (event) => {
            // Fetch researcher's data based on event.researchId (if present)
            if (event.researchId) {
              const research = await AuthService.getResearch(event.researchId);
              return { ...event, researchName: research.title };
            }
            return { ...event, researchName: "not releted" };
          })
        );
        setEvents(updatedData);
      } catch (error) {
        console.error("Error fetching event data:", error);
      }
    };

    fetchEvents();
  }, []);

  const updateEventClicked = (event) => {
    setSelectedEventForUpdate(event);
    setIsCurrentStateUpdate(true);
  };

  const closeTheUpdateStateForm = () => {
    setIsCurrentStateUpdate(false);
  };

  const deleteEventClicked = async (eventId) => {
    const isConfirmed = window.confirm(`Are you sure you want to delete event ${eventId}?`);
    if (!isConfirmed) return;

    const response = await AuthService.deleteEvents(eventId);
    if (response.error) {
      return console.error(response.error);
    }
    // Fetch updated events list
  };
  const Event = ({ img, title, date, description }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={img} name={title} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {title}
        </MDTypography>
        <MDBox display="flex" flexDirection="column" gap="4px">
          <MDTypography variant="caption" fontWeight="medium" color="textSecondary">
            {date}
          </MDTypography>
          <MDTypography variant="caption" color="textSecondary">
            {description.length > 50 ? `${description.substring(0, 50)}...` : description}
          </MDTypography>
        </MDBox>
      </MDBox>
    </MDBox>
  );
  

  // Dynamically generate rows
  const rows = events.map((event) => ({
    event: (
      <Event
        img = {event.banner}
        title={event.title}
        description={event.description}
      />
    ),
    research: (
      <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
        {event.researchName}
      </MDTypography>
    ),
    date: (
      <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
        {new Date(event.date).toLocaleDateString()}
      </MDTypography>
    ),
    location: (
      <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
        {event.location}
      </MDTypography>
    ),
    action: (
      userRole === "admin" ? (
        <MDBox display="flex" gap="8px">
          <MDTypography
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
            onClick={() => updateEventClicked(event)}
            aria-label="Edit event"
            sx={{ '&:hover': { cursor: 'pointer', textDecoration: 'underline' } }}
          >
            Edit
          </MDTypography>
          <MDTypography
            component="a"
            href="#"
            variant="caption"
            color="text"
            fontWeight="medium"
            onClick={() => deleteEventClicked(event.eventId)}
            aria-label="Delete event"
            sx={{ '&:hover': { cursor: 'pointer', textDecoration: 'underline' } }}
          >
            Delete
          </MDTypography>
        </MDBox>
      ) : (
        <MDBox display="flex" gap="8px">
          {/* You can add a message for non-admin users */}
          <MDTypography variant="caption" color="text" fontWeight="medium">
            No actions available
          </MDTypography>
        </MDBox>
      )
    )    
  }));

  return {
    columns: [
      { Header: "event", accessor: "event", width: "20%", align: "left" },
      { Header: "research", accessor: "research", align: "left" },
      { Header: "date", accessor: "date", align: "center" },
      { Header: "location", accessor: "location", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],
    rows,
    update: {
      isCurrentStateUpdate,
      selectedEventForUpdate,
      closeTheUpdateStateForm,
    },
  };
}


