import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";
import MenuItem from "@mui/material/MenuItem";
import { Select, InputLabel, FormControl, Checkbox, FormControlLabel } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import AuthService from "../../services/auth-service.js";
import FilePreview from "examples/Forms/filePriviews.js";
import { useEffect, useState, useContext } from "react";
import { AuthContext } from "context"; // Adjust the path as needed

const PublicationForm = ({ entity, isUpdateMode, onClose }) => {
  const { userName } = useContext(AuthContext);
  const [notification, setNotification] = useState({ show: false, message: "", type: "info" });
  const [publicationData, setPublicationData] = useState({
    title: "",
    description: "",
    status: "",
    author: "",
    category: ""
  });
  const [accounts, setAccounts] = useState([]);
  const [files, setFiles] = useState(null);
  const [existingFile, setExistingFile] = useState(null);
  const [fileUpdateChecked, setFileUpdateChecked] = useState(false);

  useEffect(() => {
    if (entity) {
      setPublicationData({ ...entity });
      if (entity.file) setExistingFile(entity.file);
    }
  }, [entity]);

  useEffect(() => {
    if (notification.show) {
      const timer = setTimeout(() => setNotification({ ...notification, show: false }), 5000);
      return () => clearTimeout(timer);
    }
  }, [notification]);

  useEffect(() => {
    const fetchAccounts = async () => {
      const response = await AuthService.getUsers(); // Adjust this service call
      setAccounts(response);
    };
    fetchAccounts();
  }, []);

  const handleChange = (e) => setPublicationData({ ...publicationData, [e.target.name]: e.target.value });

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!publicationData.title || !publicationData.description) {
      setNotification({ show: true, message: "Please fill in all required fields.", type: "error" });
      return;
    }
    console.log(publicationData)
    publicationData.isFileRemoved = existingFile === null;
    publicationData.author = userName;
    console.log(publicationData.author)
    try {
      const fullData = { data: { attributes: publicationData } };
      const formData = new FormData();
      if (files) formData.append("file", files);
      formData.append("data", JSON.stringify(fullData));

      let response;
      if (isUpdateMode) {
        response = await AuthService.updatePublications({ fullPublicationData: formData, PublicationId: publicationData.publicationId });
      } else {
        response = await AuthService.createPublications(formData);
      }

      setNotification({ show: true, message: `Publication ${isUpdateMode ? "updated" : "created"} successfully!`, type: "success" });
      if (onClose) onClose();
    } catch (error) {
      setNotification({ show: true, message: `Error: ${error.message || "An error occurred"}`, type: "error" });
    }
  };

  const handleFileChange = (event) => {
    setExistingFile(null);
    setFiles(event.target.files[0]);
  };

  const handleRemoveFile = (type) => {
    if (type === 'initial') setExistingFile(null);
    if (type === 'new') setFiles(null);
  };

  const handleCheckboxChange = (e) => {
    setFileUpdateChecked(e.target.checked);
    if (!e.target.checked) setFiles(null);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <MDBox component="form" role="form" onSubmit={submitHandler} display="flex" flexDirection="column">
        <MDTypography variant="h5">{isUpdateMode ? "Update Publication" : "Create Publication"}</MDTypography>

        {/* Title Input */}
        <MDBox mb={2}>
          <MDTypography variant="body2">Title</MDTypography>
          <MDInput type="text" name="title" value={publicationData.title} onChange={handleChange} fullWidth />
        </MDBox>

        {/* Description Text Area */}
        <MDBox mb={2}>
          <MDTypography variant="body2">Description</MDTypography>
          <MDInput type="textarea" name="description" value={publicationData.description} onChange={handleChange} fullWidth />
        </MDBox>

        {/* Category Select */}
        <MDBox mb={2}>
          <MDTypography variant="body2">Category</MDTypography>
          <FormControl fullWidth>
            <InputLabel>Category</InputLabel>
            <Select name="category" value={publicationData.category} onChange={handleChange}>
              <MenuItem value="paper publication">Paper Publication</MenuItem>
              <MenuItem value="conference presentations">Conference Presentations</MenuItem>
              <MenuItem value="case studies">Case Studies</MenuItem>
              <MenuItem value="technologyTransfer"> Technology Transfer</MenuItem>
            </Select>
          </FormControl>
        </MDBox>

        {/* Status Select */}
        <MDBox mb={2}>
          <MDTypography variant="body2">Status</MDTypography>
          <FormControl fullWidth>
            <InputLabel>Status</InputLabel>
            <Select name="status" value={publicationData.status} onChange={handleChange}>
              <MenuItem value="draft">Draft</MenuItem>
              <MenuItem value="published">Published</MenuItem>
              <MenuItem value="archived">Archived</MenuItem>
            </Select>
          </FormControl>
        </MDBox>

        {/* File Upload and Preview */}
        <MDBox mb={2}>
          <MDInput type="file" accept=".pdf,.doc,.docx" onChange={handleFileChange} fullWidth />
          <MDBox display="flex" flexDirection="row" flexWrap="wrap" mt={2}>
            {existingFile && (
              <FilePreview file={existingFile} onRemove={() => handleRemoveFile('initial')} />
            )}
            {files && (
              <FilePreview file={files} onRemove={() => handleRemoveFile('new')} />
            )}
          </MDBox>
        </MDBox>

        {/* Notification Alert */}
        {notification.show && <MDAlert color={notification.type}>{notification.message}</MDAlert>}

        {/* Submit Button */}
        <MDBox display="flex" justifyContent="space-between">
          <MDButton variant="outlined" color="secondary" onClick={onClose}>
            Close
          </MDButton>
          <MDButton variant="gradient" color="info" type="submit">
            {isUpdateMode ? "Update" : "Create"}
          </MDButton>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default PublicationForm;


