import HttpService from "./htttp.service";

class AuthService {
  authEndpoint = process.env.API_URL;

  login = async (payload) => {
    const loginEndpoint = 'login';
    return await HttpService.post(loginEndpoint, payload);
  };

  register = async (credentials) => {
    const registerEndpoint = 'register';
    return await HttpService.post(registerEndpoint, credentials);
  };

  logout = async () => {
    const logoutEndpoint = 'logout';
    return await HttpService.post(logoutEndpoint);
  };

  forgotPassword = async (payload) => {
    const forgotPassword = 'password-forgot';
    return await HttpService.post(forgotPassword, payload);
  }

  resetPassword = async (credentials) => {
    const resetPassword = 'password-reset';
    return await HttpService.post(resetPassword, credentials);
  }

  getProfile = async() => {
    const getProfile = 'me';
    return await HttpService.get(getProfile);
  }

  // users
  getUsers = async() => {
    const getUsers = 'user';
    return await HttpService.get(getUsers);
  }
  getUser = async(userName) => {
    const getUser = `user/${userName}`;
    return await HttpService.post(getUser);
  }
  createUsers = async(data) => {
    console.log(data)
    const createUsers = 'user';
    return await HttpService.post(createUsers, data);
  }
  updateUsers = async({formData, userName}) => {
    const updateUsers = `user/${userName}`;
    return await HttpService.patch(updateUsers, formData);
  }
  deleteUsers = async(userName) => {
    const deleteUsers = `user/${userName}`;
    return await HttpService.delete(deleteUsers);
  }

  // research
  getResearches = async() => {
    const getResearches = 'research';
    return await HttpService.get(getResearches);
  }
  getResearch = async(researchId) => {
    const getResearch = `research/${researchId}`;
    return await HttpService.post(getResearch);
  }
  createResearches = async(data) => {
    const createResearches = 'research';
    return await HttpService.post(createResearches, data);
  }
  updateResearches = async({fullResearchData, researchId}) => {
    const updateResearches = `research/${researchId}`;
    return await HttpService.patch(updateResearches, fullResearchData);
  }
  deleteResearches = async(researchId) => {
    const deleteResearches = `research/${researchId}`;
    return await HttpService.delete(deleteResearches);
  }

  // project
  getProjects = async() => {
    const getProjects = 'project';
    return await HttpService.get(getProjects);
  }
  getProject = async(projectId) => {
    const getProject = `project/${projectId}`;
    return await HttpService.post(getProject);
  }
  createProjects = async(data) => {
    const createProjects = 'project';
    return await HttpService.post(createProjects, data);
  }
  updateProjects = async({fullProjectData, projectId}) => {
    const updateProjects = `project/${projectId}`;
    return await HttpService.patch(updateProjects, fullProjectData);
  }
  deleteProjects = async(projectId) => {
    const deleteProjects = `project/${projectId}`;
    return await HttpService.delete(deleteProjects);
  }

  //researchImages
  getResearchImages = async() => {
    const getResearchImages = 'researchImages';
    return await HttpService.get(getResearchImages);
  }
  getResearchImage = async(researchId) => {
    const getResearchImage = `researchImages/${researchId}`;
    return await HttpService.post(getResearchImage);
  }
  createResearchImages = async(data) => {
    const createResearchImages = 'researchImages';
    return await HttpService.post(createResearchImages, data);
  }
  updateResearchImages = async({fullResearchImagesData, researchImagesId}) => {
    const updateResearchImages= `researchImages/${researchImagesId}`;
    return await HttpService.patch(updateResearchImages, fullResearchImagesData);
  }
  deleteResearchImages = async(researchImagesId) => {
    const deleteResearchImages = `researchImages/${researchImagesId}`;
    return await HttpService.delete(deleteResearchImages);
  }
  //projectImages
  getProjectImages = async() => {
    const getProjectImages = 'projectImages';
    return await HttpService.get(getProjectImages);
  }
  getProjectImage = async(projectId) => {
    const getProjectImage = `projectImages/${projectId}`;
    return await HttpService.post(getProjectImage);
  }
  createProjectImages = async(data) => {
    const createProjectImages = 'projectImages';
    return await HttpService.post(createProjectImages, data);
  }
  updateProjectImages = async({fullProjectImagesData, projectImagesId}) => {
    const updateProjectImages = `projectImages/${projectImagesId}`;
    return await HttpService.patch(updateProjectImages, fullProjectImagesData);
  }
  deleteProjectImages = async(projectImagesId) => {
    const deleteProjectImages = `projectImages/${projectImagesId}`;
    return await HttpService.delete(deleteProjectImages);
  }

  // events
  getEvents = async() => {
    const getEvents = 'event';
    return await HttpService.get(getEvents);
  }
  getEvent = async(eventId) => {
    const getEvent = `event/${eventId}`;
    return await HttpService.post(getEvent);
  }
  createEvents = async(data) => {
    const createEvents = 'event';
    return await HttpService.post(createEvents, data);
  }
  updateEvents = async({fullEventData, eventId}) => {
    const updateEvents = `event/${eventId}`;
    return await HttpService.patch(updateEvents, fullEventData);
  }
  deleteEvents = async(eventId) => {
    const deleteEvents = `event/${eventId}`;
    return await HttpService.delete(deleteEvents);
  }
  
  //files
  getFiles = async() => {
    const getFiles = 'files';
    return await HttpService.get(getFiles);
  }
  downloadFiles = async(fileName) => {
    const downloadFiles = `files/${fileName}`;
    return await HttpService.post(downloadFiles);
  }
  uploadFiles = async(data) => {
    const uploadFiles = 'files';
    return await HttpService.post(uploadFiles, data);
  }
  deleteFiles = async(fileName) => {
    const deleteFiles = `files/${fileName}`;
    return await HttpService.delete(deleteFiles);
  }

  //notifications
  getNotifications = async() => {
    const getNotifications = 'notification';
    return await HttpService.get(getNotifications);
  }
  getNotification = async(notificationId) => {
    const getNotification = `notification/${notificationId}`;
    return await HttpService.post(getNotification);
  }
  createNotifications = async(data) => {
    const createNotifications = 'notification';
    return await HttpService.post(createNotifications, data);
  }
  updateNotifications = async({fullNotificationsData, notificationId}) => {
    const updateNotifications = `notification/${notificationId}`;
    return await HttpService.patch(updateNotifications, fullNotificationsData);
  }
  deleteNotifications = async(notificationId) => {
    const deleteNotifications = `notification/${notificationId}`;
    return await HttpService.delete(deleteNotifications);
  }

  //testimonial
  getTestimonials = async() => {
    const getTestimonials = 'testimonial';
    return await HttpService.get(getTestimonials);
  }
  getTestimonial = async(testimonialId) => {
    const getTestimonial = `testimonial/${testimonialId}`;
    return await HttpService.post(getTestimonial);
  }
  createTestimonials = async(data) => {
    console.log('form create testmonials')
    const createTestimonials = 'testimonial';
    return await HttpService.post(createTestimonials, data);
  }
  updateTestimonials = async({fullTestimonialData,testimonialId}) => {
    const updateTestimonials = `testimonial/${testimonialId}`;
    return await HttpService.patch(updateTestimonials, fullTestimonialData);
  }
  deleteTestimonials = async(testimonialId) => {
    const deleteTestimonials = `testimonial/${testimonialId}`;
    return await HttpService.delete(deleteTestimonials);
  }

  //carrerOpportunitie
  getOpportunities = async() => {
    const getOpportunities = 'carrerOpportunitie';
    return await HttpService.get(getOpportunities);
  }
  getOpportunitie = async(opportunitieId) => {
    const getOpportunitie = `carrerOpportunitie/${opportunitieId}`;
    return await HttpService.post(getOpportunitie);
  }
  createOpportunities = async(data) => {
    const createOpportunities = 'carrerOpportunitie';
    return await HttpService.post(createOpportunities, data);
  }
  updateOpportunities = async({fullOpportunitieData,opportunitieId}) => {
    const updateOpportunities = `carrerOpportunitie/${opportunitieId}`;
    return await HttpService.patch(updateOpportunities, fullOpportunitieData);
  }
  deleteOpportunities = async(opportunitieId) => {
    const deleteOpportunities = `carrerOpportunitie/${opportunitieId}`;
    return await HttpService.delete(deleteOpportunities);
  }
  //publication
  getPublications = async() => {
    const getPublications = 'publication';
    return await HttpService.get(getPublications);
  }
  getPublication = async(publicationId) => {
    const getPublication = `publication/${publicationId}`;
    return await HttpService.post(getPublication);
  }
  createPublications = async(data) => {
    const createPublications = 'publication';
    return await HttpService.post(createPublications, data);
  }
  updatePublications = async({fullPublicationData,PublicationId}) => {
    const updatePublications = `publication/${PublicationId}`;
    return await HttpService.patch(updatePublications, fullPublicationData);
  }
  deletePublications = async(PublicationId) => {
    const deletePublications = `publication/${PublicationId}`;
    return await HttpService.delete(deletePublications);
  }
  
  //admin
  updateProfile = async (newInfo) => {
    const updateProfile = "me";
    return await HttpService.patch(updateProfile, newInfo);
  }
}

export default new AuthService();
