import { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDAlert from "components/MDAlert";
import MenuItem from "@mui/material/MenuItem";
import { Select, InputLabel, FormControl, Checkbox, FormControlLabel } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import AuthService from "../../services/auth-service.js";
import ImagePreview from "examples/Forms/imagePreviews.js";

const EventForm = ({ event, isUpdateMode, onClose }) => {
  const [notification, setNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [eventData, setEventData] = useState({
    title: "",
    description: "",
    date: "",
    // status: "",
    location: "",
    // researchId: "",
    isRelatedToResearch: false,
  });
  const [images, setImages] = useState(null)
  const [initialImages, setInitialImages] = useState(null)
  const [researchList, setResearchList] = useState([]);

  // Fetch research IDs for the researchId select dropdown
  useEffect(() => {
    const fetchResearchList = async () => {
      const response = await AuthService.getResearches(); // Adjust this service call
      setResearchList(response.data);
    };
    fetchResearchList();
  }, []);

  useEffect(() => {
    if (notification) {
      const timer = setTimeout(() => setNotification(false), 5000);
      return () => clearTimeout(timer);
    }
  }, [notification]);

  useEffect(() => {
    if (event) {
      setEventData({ ...event });
    }
  }, [event]);

  const handleChange = (e) => {
    setEventData({ ...eventData, [e.target.name]: e.target.value });
  };

  const handleCheckboxChange = (e) => {
    setEventData({ ...eventData, [e.target.name]: e.target.checked });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if(!eventData.title || !eventData.description || !eventData.date || !eventData.location){
      setNotificationMessage("Please fill in all required fields.");
      setNotification(true);
      return;
    }
    const fullData = {
      data: {
        attributes: eventData,
      },
    };

    const formData = new FormData();
    formData.append("images", images);
    formData.append("data", JSON.stringify(fullData)); 
    const response = isUpdateMode
      ? await AuthService.updateEvents({ fullEventData: formData, eventId: eventData.eventId }) // Update API
      : await AuthService.createEvents(formData); // Create API

    setNotificationMessage(isUpdateMode ? "Event has been updated" : "Event created successfully");
    if (onClose) onClose();
  };
  const changeDate = (dated) => {
    const date = new Date(dated);
    const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
    return formattedDate
  }
  if(event.date){
    event.date = changeDate(event.date)
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <MDBox component="form" role="form" onSubmit={submitHandler} display="flex" flexDirection="column">
        <MDTypography variant="h5">
          {isUpdateMode ? "Update Event" : "Create Event"}
        </MDTypography>

        {/* Title Input */}
        <MDBox mb={2}>
          <MDTypography variant="body2">Title</MDTypography>
          <MDInput type="text" name="title" value={eventData.title} onChange={handleChange} fullWidth />
        </MDBox>

        {/* Description Text Area */}
        <MDBox mb={2}>
          <MDTypography variant="body2">Description</MDTypography>
          <MDInput type="textarea" name="description" value={eventData.description} onChange={handleChange} fullWidth />
        </MDBox>

        {/* Date Input */}
        <MDBox mb={2}>
          <MDTypography variant="body2">Date</MDTypography>
          <MDInput type="date" name="date" value={changeDate(eventData.date)} onChange={handleChange} fullWidth />
        </MDBox>

        {/* Status Select
        <MDBox mb={2}>
          <MDTypography variant="body2">Status</MDTypography>
          <FormControl fullWidth>
            <InputLabel>Status</InputLabel>
            <Select name="status" value={eventData.status} onChange={handleChange}>
              <MenuItem value="ongoing">Ongoing</MenuItem>
              <MenuItem value="completed">Completed</MenuItem>
              <MenuItem value="starting">Pending</MenuItem>
            </Select>
          </FormControl>
        </MDBox> */}

        {/* Location Input */}
        <MDBox mb={2}>
          <MDTypography variant="body2">Location</MDTypography>
          <MDInput type="text" name="location" value={eventData.location} onChange={handleChange} fullWidth />
        </MDBox>

        {/* Is Related to Research Checkbox */}
        <MDBox mb={2}>
          <FormControlLabel
            control={
              <Checkbox
                name="isRelatedToResearch"
                checked={eventData.isRelatedToResearch}
                onChange={handleCheckboxChange}
              />
            }
            label="Is this event related to research?"
          />
        </MDBox>

        {/* Research ID Select (conditionally rendered) */}
        {eventData.isRelatedToResearch && (
          <MDBox mb={2}>
            <MDTypography variant="body2">Research ID</MDTypography>
            <FormControl fullWidth>
              <InputLabel>Research ID</InputLabel>
              <Select name="researchId" value={eventData.researchId} onChange={handleChange}>
                {researchList &&
                  researchList.map((research) => (
                    <MenuItem key={research.researchId} value={research.researchId}>
                      {research.title}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </MDBox>
        )}
        <MDBox mb={2}>
          <MDTypography variant="body2" color="text" ml={1}>
            Upload Banner
          </MDTypography>
          <MDInput
            type="file"
            name="banner"
            onChange={(e) => {
              setInitialImages(null); 
              setImages(e.target.files[0])
            }}
            fullWidth
          />
          <MDBox display="flex" flexDirection="row" flexWrap="wrap">
        {/* Display initial images */}
        {initialImages && (
          <ImagePreview
            key={initialImages.id}
            image={initialImages}
            onRemove={() => handleRemoveImage(setInitialImages(null))}
            noThumbnail = {true}
          />
        )}
        {/* Display new images */}
        {images && (
          <ImagePreview
            key={images}
            image={images}
            onRemove={() => handleRemoveImage(setImages(null))}
            noThumbnail = {true}
          />
        )} 
      </MDBox>
        </MDBox>

        {/* Notification */}
        {notification && (
          <MDAlert color="info" mt="20px">
            <MDTypography variant="body2" color="green">
              {notificationMessage}
            </MDTypography>
          </MDAlert>
        )}

        {/* Submit Button */}
        <MDBox display="flex" justifyContent="space-between">
          <MDButton variant="outlined" color="secondary" onClick={onClose}>
            Close
          </MDButton>
          <MDButton variant="gradient" color="info" type="submit">
            {isUpdateMode ? "Update" : "Create"}
          </MDButton>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};
export default EventForm; 


