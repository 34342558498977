import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
const ImagePreview = ({ image, isThumbnail, onRemove, onSelectThumbnail, noThumbnail }) => {
    console.log(image)
    const imgLinkReplacer = (link) => {
        try {
          const updatedLoc = link.replaceAll("\\", "/").split("/").pop();
          console.log(updatedLoc)
          return `https://server.belestech.com/uploads/${updatedLoc}`;
        } catch (error) {
          console.error(error);
          return "";
        }
      };
    // Check if image is a valid File or Blob
    const imageUrl = image instanceof File ? URL.createObjectURL(image) : imgLinkReplacer(image.file);
  
    return (
      <MDBox position="relative" m={1} display="flex" flexDirection="column" justifyContent="center">
        <MDBox>
          {imageUrl && <img src={imageUrl} alt="preview" style={{ width: 100, height: 100 }} />}
        </MDBox>
        <MDBox >
          <MDButton variant="text" color="error" size="small" onClick={onRemove} center>
            Remove
          </MDButton>
          {!noThumbnail && (<MDButton variant="text" color={isThumbnail ? "success" : "info"} size="small" onClick={onSelectThumbnail}>
            {isThumbnail ? "Thumbnail" : "Set as Thumbnail"}
          </MDButton>)}
        </MDBox>
      </MDBox>
    );
  };
  
  export default ImagePreview;
  