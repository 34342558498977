import React from 'react'
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import eventsTableData from "layouts/events/data/eventsTableData.js";
import authService from "../../services/auth-service.js";

import Form from './form.js'

import { useEffect, useState, useContext } from "react";
import { AuthContext } from "context"; // Adjust the path as neede

 const Events = () => {
  const { isAuthenticated, userRole } = useContext(AuthContext);
    const { columns, rows, update } = eventsTableData();
    const [view, setView] = useState('view')
    const [selected, setSelected] = useState({})
    const eventsCreateButtonClicked = async () =>{
    setView('create');
    }
    useEffect(() => {
      if (update.isCurrentStateUpdate) {
        setView('update');
        setSelected(update.selectedEventForUpdate);
      }
    }, [update]);
    const closeUserProfile = () => {
        update.closeTheUpdateStateForm()
        setView('view'); // Close the form
    };
    
    if (view === 'view'){
      return (
        <DashboardLayout>
          <DashboardNavbar />
          <MDBox pt={6} pb={3}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    mt={-3}
                    py={3}
                    px={2}
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="info"
                  >
                    <MDTypography variant="h6" color="white" display = 'flex' alignItems="bottom" gap="5px">
                      events
                      {userRole === "admin" && (<MDBox onClick={eventsCreateButtonClicked}>
                        <img
                          src="/icons8-edit-100.png"
                          width={20}
                          alt="create"
                        />
                      </MDBox>)}
                    </MDTypography>
                  </MDBox>
                  <MDBox pt={3}>
                    <DataTable
                      table={{ columns, rows }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
          <Footer />
        </DashboardLayout>
      );
    }
    if (view === 'create'){
      return (
        <Form 
          event = {{}}
          isUpdateMode = {false}
          onClose = {closeUserProfile}
        />
    )}
    if (view === 'update'){
      return (
        <Form
          event = {selected}
          isUpdateMode = {true}
          onClose = {closeUserProfile}
        />
    )}
}
export default Events